export default function useBrowserDetect() {
  const isMobileSafari = ref(false);

  onMounted(() => {
    const userAgent = navigator.userAgent;
    const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    const isMobile = /iPhone|iPad|iPod/.test(userAgent);

    if (isSafari && isMobile) {
      isMobileSafari.value = true;
    }
  });

  return { isMobileSafari };
}
